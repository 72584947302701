import React from "react";
import flower1 from "../../images/flower1.png";
import flower2 from "../../images/flower2.png";
import flower3 from "../../images/flower3.png";


export default function ReviewStats(props) {
  return (
    <div className="review-wrap">
      <div className="review-section">
        <img className="review-stats-img" src={flower1} alt="unhatched egg" />
        <span>{props.weakWordsCount}</span>
        <span>{props.weakWordsCount !== 1 ? "weak words" : "weak word"}</span>
      </div>

      <div className="review-section">
        <img className="review-stats-img" src={flower2} alt="hatching egg" />
        <span>{props.mediumWordsCount}</span>
        <span>{props.mediumWordsCount !== 1 ? "medium words" : "medium word"}</span>
      </div>

      <div className="review-section">
        <img className="review-stats-img" src={flower3} alt="hatched egg" />
        <span>{props.strongWordsCount}</span>
        <span>{props.strongWordsCount !== 1 ? "strong words" : "strong word"}</span>
      </div>
    </div>
  );
}
