import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import ReadingList from "./ReadingList";
import { useAuth } from "../../contexts/AuthContext";
import { database } from "../../firebase";
import partyhatcat from "../../images/partyhatcatfinal.png";
import hanbokcat from "../../images/cathanbokfinal.png";
import catball from "../../images/catballfinal.png";

export default function Read() {
  const { currentUser } = useAuth();
  const [scrolledLesson, setScrolledLesson] = useState("");
  const [finishedLessons, setFinishedLessons] = useState([]);
  const todayDate = new Date();
  const [level1Lessons, setLevel1Lessons] = useState([]);
  const [level2Lessons, setLevel2Lessons] = useState([]);
  const [level3Lessons, setLevel3Lessons] = useState([]);
  const [level4Lessons, setLevel4Lessons] = useState([]);
  const [pickedCat, setPickedCat] = useState();

  const adjustLearningHistory = (learningHistory) => {
    if (learningHistory) {
      const asArray = Object.entries(learningHistory);
      const filtered = asArray.filter(
        ([key, value]) =>
          !(
            todayDate - new Date(key) >
            1000 /*ms*/ *
              60 /*s*/ *
              60 /*min*/ *
              24 /*h*/ *
              30 /*days*/ *
              3 /*months*/
          )
      );
      const newObj = Object.fromEntries(filtered);

      database.users.doc(currentUser.uid).update({
        learningHistory: { ...newObj },
      });
    }
  };

  useEffect(() => {
    database.lessons.where("components.reading", "!=", false)
      .get()
      .then((querySnapshot) => {
        console.log("search done")
        querySnapshot.forEach((doc) => {
          if (doc.data().lessonLvl === 1) {
            setLevel1Lessons(prevState => [...prevState, doc.data().lessonNr])
          } else if (doc.data().lessonLvl === 2) {
            setLevel2Lessons(prevState => [...prevState, doc.data().lessonNr])
          } else if (doc.data().lessonLvl === 3) {
            setLevel3Lessons(prevState => [...prevState, doc.data().lessonNr])
          } else if (doc.data().lessonLvl === 4) {
            setLevel4Lessons(prevState => [...prevState, doc.data().lessonNr])
          }
          
        });
      });
      database.lessons.where("components.dialogue", "!=", false)
      .get()
      .then((querySnapshot) => {
        console.log("search done")
        querySnapshot.forEach((doc) => {
          if (doc.data().lessonLvl === 1) {
            setLevel1Lessons(prevState => [...prevState, doc.data().lessonNr])
          } else if (doc.data().lessonLvl === 2) {
            setLevel2Lessons(prevState => [...prevState, doc.data().lessonNr])
          } else if (doc.data().lessonLvl === 3) {
            setLevel3Lessons(prevState => [...prevState, doc.data().lessonNr])
          } else if (doc.data().lessonLvl === 4) {
            setLevel4Lessons(prevState => [...prevState, doc.data().lessonNr])
          }
        });
      });
    database.users
      .doc(currentUser.uid)
      .get()
      .then((doc) => {
        if (doc.data().finishedLevel1.length > 0) {
          setScrolledLesson(
            (Math.max(...doc.data().finishedLevel1) + 1).toString()
          );
        } else {
          setScrolledLesson("1");
        }
        setFinishedLessons([...doc.data().finishedLevel1]);
        adjustLearningHistory(doc.data().learningHistory, doc.data().streak);
      });
  }, []);

  useEffect(() => {
    const catImages = [partyhatcat, hanbokcat, catball]
    setPickedCat(catImages[Math.floor(Math.random() * catImages.length)])
  }, [])

  return (
    <React.Fragment>
      <div className="dashboard-wrap">
        <Navbar active="read" />
        <ReadingList
          level1LessonNrs={level1Lessons}
          level2LessonNrs={level2Lessons}
          level3LessonNrs={level3Lessons}
          level4LessonNrs={level4Lessons}
          setScrolledLesson={setScrolledLesson}
          currentLesson={scrolledLesson}
          finishedLessons={finishedLessons}
        />
        {/* <LessonListDesc currentLesson={scrolledLesson} /> */}
        <img className="lesson-list-img" src={pickedCat} />
      </div>
    </React.Fragment>
  );
}
