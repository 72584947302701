import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { database } from "../../firebase";

const LessonCardCompact = (props) => {
  const [lessonNr, setLessonNr] = useState(0);
  const [lessonName, setLessonName] = useState("");
  const [lessonDesc, setLessonDesc] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    function handleResize() {
      let cards = document.querySelectorAll('.lesson-card-compact')
      for (let i = 0; i < cards.length; i++) {
        cards[i].style.height = cards[i].offsetWidth+"px"
      }
    }

    if (document.querySelectorAll('.lesson-card-compact')) {
      handleResize()
      window.addEventListener('resize', handleResize);
    }
  }, []);

  function lessonClicked() {
    navigate("/lessons/" + props.lessonNr);
  }

  useEffect(() => {
    setLessonNr(props.lessonNr);
  }, [props]);

  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

/*   useEffect(() => {
    const stamps = document.getElementsByClassName("lesson-card-stamp-done");
    for (var i = 0; i < stamps.length; i++) {
      let randomNr = getRandomInt(90) - 45;
      stamps[i].style.transform = "rotate(" + randomNr + "deg)";
    }
  }); */

  useEffect(() => {
    if (props.setLoaded && lessonName !== "") {
      props.setLoaded(true)
    }
  }, [lessonName])

  return (
    <>
      <div className={`lesson-card-compact before-learn-tab mb-3 ${props.isFinished ? "compact-lesson-finished" : ""}`} onClick={lessonClicked}>
        <span className="compact-lesson-nr"><strong>{props.displayNr}</strong></span>
      </div>
    </>
  );
};

export default LessonCardCompact;
