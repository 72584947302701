import React from 'react'

export default function Close() {

  return (
    <>
      <div className='footer'>
        <span className='align-left'>Learn Korean on Ono Dojang for free!</span>
        <span className='align-right'>© 2023 OnoDojang. All rights reserved.</span>
      </div>
    </>
  )
}
