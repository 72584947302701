import React, { useEffect, useState } from "react";
import LessonCardCompact from "./LessonCardCompact";
import { TiArrowSortedDown } from "react-icons/ti";

export default function LessonList(props) {
  const [showLevel, setShowLevel] = useState(1);

  const lessonCards = []
  /* for (let i = 1; i <= 15; i++) {
    lessonCards.push(<LessonCardCompact key={"lesson"+i} lessonNr={i} isFinished={isFinished(i)}/>);
  } */

  for (let i = 0; i < props.inLearnLessons.length; i++) {
    lessonCards.push(<LessonCardCompact key={"lesson"+props.inLearnLessons[i]} lessonNr={props.inLearnLessons[i]} displayNr={i+1} isFinished={isFinished(props.inLearnLessons[i])}/>)
  }

  function isFinished(lessonNr) {
    return props.finishedLessons.includes(lessonNr);
  }

  function setArrowRotation(level) {
    if (level === 0) {
      document.querySelector('#lesson-arrow-1').style.transform = "rotate(0deg)"
    } else if (level === 1) {
      document.querySelector('#lesson-arrow-1').style.transform = "rotate(180deg)"
    }
  }

  useEffect(() => {
    setArrowRotation(showLevel)
  }, [showLevel])
  

  function handleLevelClick(level) {
    if (level === showLevel) {
      setShowLevel(0)
    } else {
      setShowLevel(level)
    }
  }

  return (
    <React.Fragment>
      <div id="lesson-cards" className="lesson-cards-compact">
        <div id="level1-lessoncards-dropdown" className="level-lessoncards" onClick={() => handleLevelClick(1)} >
          <span className="level-lessoncards-span"><strong>LEVEL 1</strong></span>
          <TiArrowSortedDown id="lesson-arrow-1" className="lessoncards-arrow"/>
        </div>
        <div className={`lessonlist-cards ${showLevel === 1 ? "render-level" : ""}`}>
          {lessonCards}
        </div>
        <p className="more-soon">More coming soon...</p>
      </div>
    </React.Fragment>
  );
}
