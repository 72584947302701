import React, { useEffect, useState } from "react";
import ReadingCard from "./ReadingCard";
import { TiArrowSortedDown } from "react-icons/ti";

export default function ReadingList(props) {
  const [showLevel, setShowLevel] = useState(1);
  const [level1Lessons, setLevel1Lessons] = useState([...new Set(props.level1LessonNrs)].sort((a, b) => a - b))
  const [level2Lessons, setLevel2Lessons] = useState([...new Set(props.level2LessonNrs)].sort((a, b) => a - b))
  const [level3Lessons, setLevel3Lessons] = useState([...new Set(props.level3LessonNrs)].sort((a, b) => a - b))
  const [level4Lessons, setLevel4Lessons] = useState([...new Set(props.level4LessonNrs)].sort((a, b) => a - b))
  const [level1Cards, setLevel1Cards] = useState([])
  const [level2Cards, setLevel2Cards] = useState([])
  const [level3Cards, setLevel3Cards] = useState([])
  const [level4Cards, setLevel4Cards] = useState([])

  useEffect(() => {
    setLevel1Lessons([...new Set(props.level1LessonNrs)].sort((a, b) => a - b))
    setLevel2Lessons([...new Set(props.level2LessonNrs)].sort((a, b) => a - b))
    setLevel3Lessons([...new Set(props.level3LessonNrs)].sort((a, b) => a - b))
    setLevel4Lessons([...new Set(props.level4LessonNrs)].sort((a, b) => a - b))
  }, [props.level1LessonNrs, props.level2LessonNrs, props.level3LessonNrs, props.level4LessonNrs])

  useEffect(() => {
    setLevel1Cards([])
    level1Lessons.forEach((nr) => {
      setLevel1Cards(prevState => [...prevState, <ReadingCard key={"reading"+nr} lessonNr={nr} isFinished={isFinished(nr)}/>]);
    })
    setLevel2Cards([])
    level2Lessons.forEach((nr) => {
      setLevel2Cards(prevState => [...prevState, <ReadingCard key={"reading"+nr} lessonNr={nr} isFinished={isFinished(nr)}/>]);
    })
    setLevel3Cards([])
    level3Lessons.forEach((nr) => {
      setLevel3Cards(prevState => [...prevState, <ReadingCard key={"reading"+nr} lessonNr={nr} isFinished={isFinished(nr)}/>]);
    })
    setLevel4Cards([])
    level4Lessons.forEach((nr) => {
      setLevel4Cards(prevState => [...prevState, <ReadingCard key={"reading"+nr} lessonNr={nr} isFinished={isFinished(nr)}/>]);
    })

    setShowLevelBasedOnFinished(Math.max(...props.finishedLessons))
  }, [level1Lessons, level2Lessons, level3Lessons, level4Lessons])

  function setShowLevelBasedOnFinished(maxFinished) {
    if (level1Lessons.includes(maxFinished)) {
      setShowLevel(1)
    } else if (level2Lessons.includes(maxFinished)) {
      setShowLevel(2)
    } else if (level3Lessons.includes(maxFinished)) {
      setShowLevel(3)
    } else if (level4Lessons.includes(maxFinished)) {
      setShowLevel(4)
    }
  }

  function isFinished(lessonNr) {
    return props.finishedLessons.includes(lessonNr);
  }

  function setArrowRotation(level) {
    if (level === 0) {
      document.querySelector('#arrow-1').style.transform = "rotate(0deg)"
      document.querySelector('#arrow-2').style.transform = "rotate(0deg)"
      document.querySelector('#arrow-3').style.transform = "rotate(0deg)"
      document.querySelector('#arrow-4').style.transform = "rotate(0deg)"
    } else if (level === 1) {
      document.querySelector('#arrow-1').style.transform = "rotate(180deg)"
      document.querySelector('#arrow-2').style.transform = "rotate(0deg)"
      document.querySelector('#arrow-3').style.transform = "rotate(0deg)"
      document.querySelector('#arrow-4').style.transform = "rotate(0deg)"
    } else if (level === 2) {
      document.querySelector('#arrow-1').style.transform = "rotate(0deg)"
      document.querySelector('#arrow-2').style.transform = "rotate(180deg)"
      document.querySelector('#arrow-3').style.transform = "rotate(0deg)"
      document.querySelector('#arrow-4').style.transform = "rotate(0deg)"
    } else if (level === 3) {
      document.querySelector('#arrow-1').style.transform = "rotate(0deg)"
      document.querySelector('#arrow-2').style.transform = "rotate(0deg)"
      document.querySelector('#arrow-3').style.transform = "rotate(180deg)"
      document.querySelector('#arrow-4').style.transform = "rotate(0deg)"
    } else if (level === 4) {
      document.querySelector('#arrow-1').style.transform = "rotate(0deg)"
      document.querySelector('#arrow-2').style.transform = "rotate(0deg)"
      document.querySelector('#arrow-3').style.transform = "rotate(0deg)"
      document.querySelector('#arrow-4').style.transform = "rotate(180deg)"
    }
  }

  useEffect(() => {
    setArrowRotation(showLevel)
  }, [showLevel])
  

  function handleLevelClick(level) {
    if (level === showLevel) {
      setShowLevel(0)
    } else {
      setShowLevel(level)
    }
  }

  return (
    <React.Fragment>
      <div id="lesson-cards" className="lesson-cards-compact">
        <div id="level1-lessoncards-dropdown" className="level-lessoncards" onClick={() => handleLevelClick(1)} >
          <span className="level-lessoncards-span"><strong>LEVEL 1</strong></span>
          <TiArrowSortedDown id="arrow-1" className="lessoncards-arrow"/>
        </div>
        <div className={`lessonlist-cards ${showLevel === 1 ? "render-level" : ""}`}>
          {console.log(level1Cards)}
          {level1Cards}
        </div>

        <div id="level1-lessoncards-dropdown" className="level-lessoncards" onClick={() => handleLevelClick(2)} >
          <span className="level-lessoncards-span"><strong>LEVEL 2</strong></span>
          <TiArrowSortedDown id="arrow-2" className="lessoncards-arrow"/>
        </div>
        <div className={`lessonlist-cards ${showLevel === 2 ? "render-level" : ""}`}>
          {level2Cards}
        </div>

        <div id="level1-lessoncards-dropdown" className="level-lessoncards" onClick={() => handleLevelClick(3)} >
          <span className="level-lessoncards-span"><strong>LEVEL 3</strong></span>
          <TiArrowSortedDown id="arrow-3" className="lessoncards-arrow"/>
        </div>
        <div className={`lessonlist-cards ${showLevel === 3 ? "render-level" : ""}`}>
          {level3Cards}
        </div>

        <div id="level1-lessoncards-dropdown" className="level-lessoncards" onClick={() => handleLevelClick(4)} >
          <span className="level-lessoncards-span"><strong>LEVEL 4</strong></span>
          <TiArrowSortedDown id="arrow-4" className="lessoncards-arrow"/>
        </div>
        <div className={`lessonlist-cards ${showLevel === 4 ? "render-level" : ""}`}>
          {level4Cards}
        </div>

        <p className="more-soon">More coming soon...</p>

      </div>
    </React.Fragment>
  );
}
