import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { database } from "../../firebase";

const ReadingCard = (props) => {
  const [lessonNr, setLessonNr] = useState(0);
  const [lessonName, setLessonName] = useState("");
  const [lessonDesc, setLessonDesc] = useState("");

  const navigate = useNavigate();

  function lessonClicked() {
    navigate("/lessons/" + props.lessonNr);
  }

  useEffect(() => {
    setLessonNr(props.lessonNr);
  }, [props]);

  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  useEffect(() => {
    if (lessonNr !== 0 && lessonNr !== undefined) {
      database.lessons
        .where("lessonNr", "==", lessonNr)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            setLessonName(doc.data().lessonName);
            setLessonDesc(doc.data().lessonDesc);
          });
        });
    }
  }, [lessonNr]);

  return (
    <>
      <div className={`reading-card before-learn-tab mb-3 ${props.isFinished ? "compact-lesson-finished" : ""}`} onClick={lessonClicked}>
        <span className="reading-lesson-name"><strong>{lessonName}</strong></span>
        <span className="reading-lesson-desc">{lessonDesc}</span>
      </div>
    </>
  );
};

export default ReadingCard;
